import {
  TooltipLink,
  TooltipParagraph,
  TooltipTitle,
} from "../tooltip/TooltipComponents";

const messages = {
  extensions: (
    <>
      <TooltipTitle>Ramais</TooltipTitle>
      <TooltipParagraph>
        Ramais são usuários com capacidade de atender e realizar ligações.
      </TooltipParagraph>
      <TooltipParagraph>
        Cada ramal tem um número utilizado para ligações entre atendentes e
        transferências. Também possuem um e-mail e senha utilizados para acessar
        a plataforma.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja a{" "}
        <TooltipLink href="manual.voki.net.br">
          introdução aos ramais
        </TooltipLink>
      </TooltipParagraph>
    </>
  ),
  "extension-config": (
    <>
      <TooltipTitle>Configurando Ramais</TooltipTitle>
      <TooltipParagraph>
        Cada ramal tem um nome de identificação, e-mail e senha para acessar a
        plataforma, e um número utilizado para ligações internas, entre
        atendentes.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja{" "}
        <TooltipLink href="manual.voki.net.br">Criar um Ramal</TooltipLink>.
      </TooltipParagraph>
    </>
  ),
  "ivr-messages": (
    <>
      <TooltipTitle>Atendimento Automático</TooltipTitle>
      <TooltipParagraph>
        Aqui você encontrará as configurações relacionadas às mensagens de áudio
        que seu cliente irá escutar quando a chamada for atendida
        automaticamente.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja o{" "}
        <TooltipLink href="manual.voki.net.br">
          manual de atendimento automático
        </TooltipLink>
        .
      </TooltipParagraph>
    </>
  ),
  "ivr-message-type": (
    <>
      <TooltipTitle>Tipo de Mensagem</TooltipTitle>
      <TooltipParagraph>
        A mensagem pré-definida e de texto são lidos por voz digital. Também é
        possível fazer upload de um arquivo de áudio.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja o{" "}
        <TooltipLink href="manual.voki.net.br">
          manual de mensagens de áudio
        </TooltipLink>
        .
      </TooltipParagraph>
    </>
  ),
  "ivr-queues": (
    <>
      <TooltipTitle>Filas</TooltipTitle>
      <TooltipParagraph>
        Filas são as opções disponíveis para o seu cliente digitar quando a
        chamada é automaticamente atendida. Cada Fila possui um número de opção
        que será digitado, um nome e uma lista de ramais que irão atender esta
        fila.
      </TooltipParagraph>
      <TooltipParagraph>
        Quando o cliente digitar a opção, ele será direcionado para um dos
        ramais ou ficará aguardando na fila até um atendente estar disponível.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja o{" "}
        <TooltipLink href="https://voki-manual-dev.web.app/docs/ura/filas">
          manual de Filas
        </TooltipLink>
        .
      </TooltipParagraph>
    </>
  ),
  "working-hours": (
    <>
      <TooltipTitle>Horários de Atendimento</TooltipTitle>
      <TooltipParagraph>
        A Voki permite cadastrar seus horários de atendimento de acordo com os
        dias da semana, incluindo pausa de almoço.
      </TooltipParagraph>
      <TooltipParagraph>
        Quando fora de expediente, poderá ser tocada uma mensagem de áudio ou
        redirecionar para um número, como um celular.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja o{" "}
        <TooltipLink href="https://voki-manual-dev.web.app/docs/ura/horarios-atendimento">
          manual de Horários de Atendimento
        </TooltipLink>
        .
      </TooltipParagraph>
    </>
  ),
  "queue-strategy": (
    <>
      <TooltipTitle>Estratégias de Fila</TooltipTitle>
      <TooltipParagraph>
        Como será selecionado qual ramal direcionar uma ligação.
      </TooltipParagraph>
      <TooltipParagraph>
        <strong>Linear:</strong> toca em ordem de cadastro dos participantes.
      </TooltipParagraph>
      <TooltipParagraph>
        <strong>Rotacionado:</strong> tenta distribuir de forma balanceada
        rotacionando entre atendentes.
      </TooltipParagraph>
      <TooltipParagraph>
        <strong>Todos ramais:</strong> toca em todos os participantes ao mesmo
        tempo.
      </TooltipParagraph>
      <TooltipParagraph>
        Para mais informações, veja o{" "}
        <TooltipLink href="manual.voki.net.br">manual de Filas</TooltipLink>.
      </TooltipParagraph>
    </>
  ),
  "ivr-single-queue": (
    <>
      <TooltipTitle>Fila de Atendimento</TooltipTitle>
      <TooltipParagraph>
        Essa será a fila que receberá as ligações que entrarem.
      </TooltipParagraph>
      <TooltipParagraph>
        Com esta configuração, não será tocado o áudio de boas-vindas,
        direcionando a ligação diretamente para a fila selecionada.
      </TooltipParagraph>
      <TooltipParagraph>
        Você ainda pode possuir outras filas cadastradas, mas não poderão
        receber ligações de fora, apenas internas.
      </TooltipParagraph>
    </>
  ),
  "copy-to-clipboard": <>Clique para copiar</>,
};

export type TooltipId = keyof typeof messages;
export default messages;
